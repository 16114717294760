import { useContext, useMemo, memo, useEffect } from 'react';

import {
  TransferContext,
  useTransfer,
} from 'features/Transfer/context/TransferContext';
import { AccountContext } from 'features/Transfer/context/AccountContext';
import {
  AccountType,
  ColumnSide,
  ExchangeAccountsType,
  WalletType,
} from 'shared/types';

import * as S from '../components/TransferColumn/styled';
import { Loader } from 'shared/components';
import { ExchangeItem } from './ExchangeItem';
import { useQueryClient } from '@tanstack/react-query';
import { toJS } from 'mobx';

interface TransferColumnProps {
  side: ColumnSide;
}

export const TransferColumn = memo(({ side }: TransferColumnProps) => {
  const queryClient = useQueryClient();
  const { isAccountsReady } = useContext(AccountContext);
  const {
    filters,
    isFromWallet,
    from,
    to,
    exchangesFrom,
    exchangesTo,
    error,
    depositAddressIsWhitelisted,
    useGetWalletsFrom,
    useGetWalletsTo,
    filtersDebounceLoading,
    selectedAccountTypes,
  } = useContext(TransferContext);

  const { fromTypes, toTypes } = useTransfer();

  const {
    isFetching: isFetchingWalletsFrom,
    isLoading: isLoadingWalletsFrom,
    isFetched: isLoadedWalletsFrom,
    data: fromAccounts,
    refetch: fetchWalletsFrom,
  } = useGetWalletsFrom();

  const {
    isFetching: isFetchingWalletsTo,
    isLoading: isLoadingWalletsTo,
    isFetched: isLoadedWalletsTo,
    data: toAccounts,
    refetch: fetchWalletsTo,
  } = useGetWalletsTo();

  useEffect(() => {
    fetchWalletsFrom();
    fetchWalletsTo();
  }, [isAccountsReady]);

  useEffect(() => {
    queryClient.cancelQueries({ queryKey: ['walletsFrom'] });
    fetchWalletsFrom();
  }, [fromTypes, filters.currencies]);

  useEffect(() => {
    queryClient.cancelQueries({ queryKey: ['walletsTo'] });
    fetchWalletsTo();
  }, [toTypes, filters.currencies]);

  const exchanges = side === 'from' ? exchangesFrom : exchangesTo;
  const accounts = side === 'from' ? fromAccounts : toAccounts;

  console.log(accounts);

  const totalAccountsCount = useMemo(() => {
    return (obj: any) =>
      obj.reduce(
        (sum: number, item: any) =>
          sum +
          item.accounts.filter((account: any) =>
            account.wallets.some((wallet: WalletType) =>
              filters.currencies.includes(wallet.currency),
            ),
          ).length,
        0,
      );
  }, [filters.currencies]);

  const currentAccountCount =
    accounts?.length > 0 ? totalAccountsCount(exchanges) : 0;

  const isEmpty = currentAccountCount === 0 && exchanges?.length > 0;
  const exchangesIds =
    accounts?.length > 0 && accounts?.map((item: AccountType) => item.exchange);
  const paddingBottom =
    !from?.account && !to?.account
      ? '0px'
      : error || !(!isFromWallet && depositAddressIsWhitelisted)
      ? '40px'
      : '100px';

  const isFetchingData =
    !isAccountsReady ||
    (side === 'from' && isFetchingWalletsFrom) ||
    (side === 'to' && isFetchingWalletsTo);

  const isNoCurrencies = filters.currencies.length === 0;
  const isShowLoader =
    (!isNoCurrencies && isFetchingData) ||
    filtersDebounceLoading === side ||
    filtersDebounceLoading === 'all';

  const isShowPlaceholderCondition =
    isEmpty && (isLoadedWalletsTo || isLoadedWalletsFrom);
  const isShowPlaceholder = isNoCurrencies || isShowPlaceholderCondition;

  return (
    <>
      <S.TransferList>
        <S.TransferListWrap paddingBottom={paddingBottom}>
          {isShowLoader ? (
            <Loader />
          ) : !isShowPlaceholder ? (
            accounts
              ?.filter((item: ExchangeAccountsType) =>
                exchangesIds.includes(item.exchange),
              )
              .map((item: ExchangeAccountsType, idx: number) => (
                <ExchangeItem key={idx} side={side} exchange={item} />
              ))
              .sort((a: any, b: any) => {
                const aExchange = a.props.exchange.exchange;
                const bExchange = b.props.exchange.exchange;

                if (aExchange === 'WALLETS') {
                  return -1; // "wallets" comes before any other string
                } else if (bExchange === 'WALLETS') {
                  return 1; // "wallets" comes before any other string
                } else {
                  return aExchange.localeCompare(bExchange); // Sort other strings alphabetically
                }
              })
          ) : (
            <S.NotAvalable>
              <p>
                <span>NOT AVAILABLE</span>
              </p>
              <p>
                There is no account with such currency to transfer. Try to pick
                another one.
              </p>
            </S.NotAvalable>
          )}
        </S.TransferListWrap>
      </S.TransferList>
    </>
  );
});
