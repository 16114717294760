import { AccountType, CurrencyType } from 'shared/types';
import { IconArrowWrap, IconArrow } from '../../components/ExchangeAbstract';
import { AccountAliases } from './AccountAliases';
import { AccountBalanceStatus } from './AccountBalanceStatus';
import { AccountExchangeIcon } from './AccountExchangeIcon';
import { AccountNameLabel } from './AccountNameLabel';
import { AccountTags } from './AccountTags';
import { AccountViolations } from './AccountViolations';
import { useMemo } from 'react';
import { ReactComponent as SvgLink } from 'shared/assets/icons/icon-external-link.svg';

import { getViolatedCurrencies } from 'features/Transfer/functions';
import * as S from './AccountStyled';
import { AccountHints } from './AccountHints';
import { AccountIncomingLimit } from './AccountIncomingLimit';
import Tippy from '@tippyjs/react';
import { IconButton, SvgIcon } from '@mui/material';

interface AccountHeaderProps {
  account: AccountType;
  subAccounts: AccountType[];
  opened: boolean;
  toggle: any;
}

export const AccountHeader = ({
  account,
  opened,
  toggle,
  subAccounts,
}: AccountHeaderProps) => {
  const wallet = account?.wallets?.find(
    ({ deposit_address }) => !!deposit_address,
  );

  const violatedCurrencies = useMemo(() => {
    return getViolatedCurrencies(account);
  }, [account]);

  const hasIncomingLimit =
    account.incoming_limit.limit !== null &&
    account.incoming_limit.used !== null;
  const isViolated =
    violatedCurrencies?.length > 0 ||
    (hasIncomingLimit &&
      Number(account.incoming_limit.used) >=
        Number(account.incoming_limit.limit));

  return (
    <S.AccountHeader isViolated={isViolated}>
      <S.AccountHeaderTop onClick={toggle}>
        <IconArrowWrap rotateIcon={opened}>
          <IconArrow />
        </IconArrowWrap>
        <AccountExchangeIcon exchange={account?.exchange} />
        <S.AccountTitle>
          <S.AccountName>
            <AccountNameLabel name={account?.name} />
            {hasIncomingLimit && (
              <AccountIncomingLimit
                used={Number(account.incoming_limit.used)}
                limit={Number(account.incoming_limit.limit)}
              />
            )}
            <AccountAliases
              address={wallet?.deposit_address as string}
              aliases={account?.sgx_aliases}
            />
            {account.thresholds && (
              <AccountViolations
                violatedCurrencies={violatedCurrencies as CurrencyType[]}
                thresholds={account.thresholds}
              />
            )}
          </S.AccountName>
          <AccountBalanceStatus
            hasSubAccounts={subAccounts?.length > 0}
            isBalanceOutdated={account?.is_balance_outdated}
            balanceUpdatedAt={account?.balance_updated_at as string}
          />
        </S.AccountTitle>
      </S.AccountHeaderTop>
      <AccountTags tags={account?.tags} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <AccountHints account={account} />
        {account?.explorer_link && (
          <Tippy
            placement="top"
            theme="transparent"
            content={'View in blockchain explorer'}
          >
            <a target='_blank' href={account?.explorer_link}><S.IconExternal /></a>
          </Tippy>
        )}
      </div>
    </S.AccountHeader>
  );
};
