import { FC, memo, useContext, useMemo, useState } from 'react';

import * as S from 'features/Transactions/view/styled';
import { CurrencyType, ExchangeApiIdType, StatusType } from 'shared/types';
import AppContext from 'shared/contexts/AppContext';
import { dateAgo, formattedDate } from 'shared/helpers/date';
import {
  Transaction,
  TransactionStatus,
  TransactionStatusType,
  TransactionVote,
  TransactionWithdrawalStatusType,
} from 'services/Transactions/types';
import Tippy from '@tippyjs/react';
import { isUndefined } from 'shared/helpers/strings';
import {
  aboutToExceedIncomingLimit,
  aboutToExceedLimit,
  aboutToExceedSingleLimit,
  canExecuteTransaction,
  canOnlyExecute,
  canRejectTransaction,
  getThreshold,
  isNoVoteTx,
  userCanExceedIncomingLimit,
} from 'shared/helpers/transaction';
import { ThresholdViolationInfo } from './ThresholdViolationInfo';
import moment from 'moment';
import { ManualResolve } from './ManualResolve';

const StatusOrder = {
  [StatusType.QUEUED]: 0,
  [StatusType.CLOSED]: 0,
  [StatusType.INITIATED]: 1,
  [StatusType.UNCONFIRMED]: 2,
  [StatusType.AWAITING_EXECUTE]: 2,
  [StatusType.PENDING]: 3,
  [StatusType.SENT]: 4,
  [StatusType.RECEIVED]: 5,
  [StatusType.COMPLETED]: 6,
  [StatusType.CANCELED]: 6,
  [StatusType.REJECTED]: 6,
  [StatusType.FAILED]: 6,
};

const hasPassed = (
  status: TransactionStatusType,
  target: TransactionStatusType,
) => {
  return StatusOrder[status] >= StatusOrder[target];
};

interface LineData {
  title: string;
  status: string;
  time?: string;
  comment?: string;
  commentColor?: string;
}
interface StatusBar {
  id: string;
  isVisible: (tx: Transaction) => boolean;
  getData: (tx: Transaction, votesToApprove?: number) => LineData;
  lines?: StatusBar[];
}

const signFlowAvailable: ExchangeApiIdType[] = ['DERIBIT'];

const emailRequiredExchanges: ExchangeApiIdType[] = [
  'BINANCE',
  'BITFINEX',
  'DERIBIT',
  'HITBTC',
  'OKCOIN',
];

const requiresEmailConfirmation = (exchange?: string) => {
  return emailRequiredExchanges.includes(exchange as ExchangeApiIdType);
};

const LINES: StatusBar[] = [
  {
    id: 'confirmations',
    isVisible: (tx) =>
      !isUndefined(tx.votes) &&
      tx?.votes?.length > 0 &&
      tx.confirmation_type !== 'ACCOUNT_POOL',
    getData: (tx: Transaction, votesToApprove?: number) => {
      const shownVotes =
        votesToApprove &&
        Math.max(0, Math.min(tx.sum_of_votes, votesToApprove));

      if (tx.status === StatusType.CLOSED) {
        return {
          title: 'Confirmations',
          status: 'failed',
          comment: `(${shownVotes} out of ${votesToApprove})`,
        };
      } else if (hasPassed(tx.status, StatusType.INITIATED)) {
        return {
          title: 'Confirmations',
          status: 'completed',
          comment: `(${shownVotes} out of ${votesToApprove})`,
        };
      } else {
        return {
          title: 'Confirmations',
          status: 'active',
          comment: `(${shownVotes} out of ${votesToApprove})`,
        };
      }
    },
  },
  {
    id: 'processing',
    isVisible: (tx: Transaction) => tx.status !== 'CLOSED',
    getData: (tx: Transaction) => {
      if (!hasPassed(tx.status, StatusType.INITIATED)) {
        return {
          title: 'Execution',
          status: 'inactive',
        };
      } else if (
        hasPassed(tx.status, StatusType.COMPLETED) &&
        tx.status === StatusType.COMPLETED
      ) {
        return {
          title: 'Confirmed',
          status: 'completed',
        };
      } else {
        if (tx.status === StatusType.UNCONFIRMED) {
          return {
            title: 'Processing',
            status: 'awaiting',
          };
        } else {
          return {
            title: 'Processing',
            status: hasPassed(tx.status, StatusType.COMPLETED)
              ? 'failed'
              : 'active',
          };
        }
      }
    },
    lines: [
      {
        id: 'confirmation',
        isVisible: (tx) =>
          hasPassed(tx.status, StatusType.UNCONFIRMED) &&
          requiresEmailConfirmation(tx?.account_from?.exchange) &&
          !!tx.account_from &&
          !!tx.account_to &&
          ![StatusType.CANCELED, StatusType.REJECTED].includes(
            tx.withdrawal_status?.toUpperCase() as TransactionWithdrawalStatusType,
          ),
        getData: (tx: Transaction) => {
          if (tx.status === StatusType.AWAITING_EXECUTE) {
            return {
              title: 'Unconfirmed on exchange',
              status: 'awaiting',
              comment: 'Execute transaction with exchange 2FA code',
            };
          } else if (hasPassed(tx.status, StatusType.PENDING)) {
            return {
              title: 'Confirmed on exchange',
              status: 'completed',
            };
          } else {
            return {
              title: 'Unconfirmed on exchange',
              status: 'awaiting',
              comment: 'Approve withdrawal in email',
            };
          }
        },
      },
      {
        id: 'withdrawal',
        isVisible: (tx) =>
          hasPassed(tx.status, StatusType.UNCONFIRMED) && !!tx.account_from,
        getData: (tx: Transaction) => {
          if (
            hasPassed(tx.status, StatusType.COMPLETED) &&
            tx.withdrawal_status === 'Rejected'
          ) {
            return {
              title: 'Withdrawal rejected',
              status: 'failed',
              time: tx.withdrawal_updated_at,
            };
          } else if (
            hasPassed(tx.status, StatusType.COMPLETED) &&
            tx.withdrawal_status === 'Canceled'
          ) {
            return {
              title: 'Withdrawal canceled',
              status: 'failed',
              time: tx.withdrawal_updated_at,
            };
          } else if (hasPassed(tx.status, StatusType.SENT)) {
            return {
              title: 'Withdrawal sent',
              status: 'completed',
              time: tx.withdrawal_updated_at,
            };
          } else if (hasPassed(tx.status, StatusType.PENDING)) {
            return {
              title: 'Withdrawal pending',
              status: 'active',
              time: tx.withdrawal_updated_at,
            };
          } else {
            return {
              title: 'Withdrawal pending',
              status: 'inactive',
              time: tx.withdrawal_updated_at,
            };
          }
        },
      },
      {
        id: 'manual_resolve',
        isVisible: (tx) => tx.is_resolved_manually,
        getData: (tx: Transaction) => {
          if (tx.status === 'FAILED') {
            return {
              title: `Resolved as "Failed" by ${tx.manually_resolved_by}`,
              status: 'failed',
              time: tx.updated_at,
              comment: tx.resolve_comment,
              commentColor: 'var(--object-secondary)',
            };
          } else {
            return {
              title: `Resolved as "Completed" by ${tx.manually_resolved_by}`,
              status: 'completed',
              time: tx.updated_at,
              comment: tx.resolve_comment,
              commentColor: 'var(--object-secondary)',
            };
          }
        },
      },
      {
        id: 'deposit',
        isVisible: (tx) =>
          hasPassed(tx.status, StatusType.UNCONFIRMED) &&
          !!tx.account_to &&
          (!isUndefined(tx.deposit_status) ||
            tx.status !== StatusType.COMPLETED) &&
          !(
            hasPassed(tx.status, StatusType.COMPLETED) &&
            (tx.withdrawal_status === 'Rejected' ||
              tx.withdrawal_status === 'Canceled')
          ),
        getData: (tx: Transaction) => {
          if (tx.is_resolved_manually && tx.status === 'FAILED') {
            return {
              title: 'Deposit failed',
              status: 'failed',
              time: tx.updated_at,
            };
          } else if (
            hasPassed(tx.status, StatusType.COMPLETED) &&
            tx.deposit_status === 'Failed'
          ) {
            return {
              title: 'Deposit failed',
              status: 'failed',
              time: tx.deposit_updated_at,
            };
          } else if (
            hasPassed(tx.status, StatusType.COMPLETED) &&
            tx.deposit_status === 'Completed'
          ) {
            return {
              title: 'Deposit confirmed',
              status: 'completed',
              time: tx.deposit_updated_at,
            };
          } else if (
            hasPassed(tx.status, StatusType.RECEIVED) &&
            tx.deposit_status === 'Pending'
          ) {
            return {
              title: 'Deposit received',
              status: 'active',
              time: tx.deposit_updated_at,
            };
          } else {
            return {
              title: 'Deposit received',
              status: 'inactive',
              time: tx.deposit_updated_at,
            };
          }
        },
      },
    ],
  },
  {
    id: 'completion',
    isVisible: (tx: Transaction) =>
      tx.status !== 'QUEUED' &&
      !(
        hasPassed(tx.status, StatusType.COMPLETED) && tx.status !== 'COMPLETED'
      ) &&
      tx.status !== 'CLOSED',
    getData: (tx: Transaction) => {
      if (tx.status === StatusType.COMPLETED) {
        return {
          title: 'Completed',
          status: 'completed',
          time: tx.updated_at,
        };
      } else {
        return {
          title: 'Completed',
          status: 'inactive',
        };
      }
    },
  },
];

interface StatusLinesProps {
  tx: Transaction;
}
const StatusLines: FC<StatusLinesProps> = memo(({ tx }) => {
  return (
    <>
      {LINES.map((line, index) => (
        <StatusLine key={index} line={line} tx={tx} />
      ))}
    </>
  );
});

interface CreatedLineProps {
  creator: string;
  createdAt: string;
}
const CreatedLine: FC<CreatedLineProps> = memo(({ creator, createdAt }) => {
  return (
    <S.StatusBarLineItem className={'completed'}>
      <S.StatusBarLineRow>
        <S.StatusBarLineTitle>
          <span>Created</span> {creator ? `by ${creator}` : ''}
        </S.StatusBarLineTitle>

        <StatusLineDate date={createdAt} />
      </S.StatusBarLineRow>
    </S.StatusBarLineItem>
  );
});

interface VoteButtonsProps {
  voteUp: any;
  voteUpAndSign: any;
  voteDown: any;
  voteError: any;
  executeTx: any;
  tx: Transaction;
}
const VoteButtons: FC<VoteButtonsProps> = memo(
  ({ voteUp, voteDown, executeTx, voteUpAndSign, tx, voteError }) => {
    const { currency, amount } = tx;
    const sumOfVotes = tx?.sum_of_votes;
    const votes = tx?.votes;
    const [isBeingVoted, setIsBeingVoted] = useState(false);

    const { user, appSettings, rates, getAccountById } = useContext(AppContext);

    const userVote = useMemo(
      () => votes?.find(({ user: name }) => name === user?.email),
      [user, votes],
    );

    const onlyExecute = canOnlyExecute(
      tx?.status,
      tx?.sum_of_votes,
      appSettings.proposal_votes_value_to_approve,
    );
    const aboutToExecute = canExecuteTransaction(
      user?.proposal_vote_weight,
      sumOfVotes,
      appSettings.proposal_votes_value_to_approve,
    );
    const aboutToReject = canRejectTransaction(
      user?.proposal_vote_weight,
      sumOfVotes,
      appSettings.proposal_votes_value_to_reject,
    );
    const toAccount = getAccountById(Number(tx?.account_to.id));
    const fromAccount = getAccountById(Number(tx?.account_from?.id));
    const noVoteTx = isNoVoteTx(fromAccount, toAccount);
    const aboutToExceedSingleTransaction =
      aboutToExceedSingleLimit(amount, currency as CurrencyType, user, rates) &&
      !noVoteTx;
    const aboutToExceed =
      aboutToExceedLimit(amount, currency as CurrencyType, user, rates) &&
      !noVoteTx;
    const exceedsIncomingLimit =
      toAccount &&
      toAccount.id !== tx?.account_from?.id &&
      aboutToExceedIncomingLimit(
        amount,
        toAccount?.incoming_limit.used,
        toAccount?.incoming_limit.limit,
        String(tx.currency) as CurrencyType,
        user,
        rates,
      );
    const canExceedIncomingLimit = userCanExceedIncomingLimit(user);

    const alreadyVoted = useMemo(() => {
      if (!votes) {
        return false;
      }

      return !!userVote;
    }, [userVote, votes]);

    const isInvalid = !tx.wallet_from || !tx.wallet_to;
    const invalidAccount = useMemo(() => {
      if (!tx.wallet_from && !tx.wallet_to) {
        return 'both sender and deposit accounts have';
      } else if (!tx.wallet_from) {
        return 'the sender account has';
      } else if (!tx.wallet_to) {
        return 'the deposit account has';
      }
    }, []);

    if (alreadyVoted && !onlyExecute) return null;

    if (!user?.proposal_vote_weight) {
      return (
        <S.VoteWeightWrap>
          {user?.proposal_vote_weight ? (
            <p>You have {user?.proposal_vote_weight} vote weight</p>
          ) : (
            <p>
              Your vote weight is not set. Please contact the administrator at
              support@multik.io
            </p>
          )}
        </S.VoteWeightWrap>
      );
    }

    if (aboutToExceedSingleTransaction) {
      const singleTxLimit = user?.single_transaction_limit;
      return (
        <S.VoteWeightWrap>
          <p>
            You can't vote for this transaction because it exceeds your maximum
            transaction limit: ${parseFloat(String(singleTxLimit))}. Please
            check your amount and try again or reach out to your administrator
            to increase your limit.
          </p>
        </S.VoteWeightWrap>
      );
    }

    if (aboutToExceed) {
      const type = user?.period_limits?.WEEK ? 'WEEK' : 'DAY';
      const displayType = type === 'WEEK' ? 'weekly' : 'daily';
      const limit = Number(user?.period_limits?.[type]);

      return (
        <S.VoteWeightWrap>
          <p>
            You can't vote for this transaction because you have exceeded your{' '}
            {displayType} transaction limit: ${parseFloat(String(limit))}.
            Please check your amount and try again or reach out to your
            administrator to increase your limit.
          </p>
        </S.VoteWeightWrap>
      );
    }

    const onVoteDown = async (e: any) => {
      await voteDown(e, setIsBeingVoted);
    };

    const onVoteUpAndSign = async (e: any) => {
      await voteUpAndSign(e, setIsBeingVoted);
    };

    const onVoteUpAndExecute = async (e: any) => {
      await voteUp(e, setIsBeingVoted);
    };

    const onExecuteTx = async (e: any) => {
      await executeTx(e, setIsBeingVoted);
    };

    const isSignFlowAvailable = signFlowAvailable.includes(
      tx.account_from?.exchange as ExchangeApiIdType,
    );

    return (
      <S.VoteWeightWrap>
        {aboutToExecute && <ThresholdViolationInfo tx={tx} />}
        {onlyExecute ? (
          <>
            <S.VoteUpButton
              onClick={onExecuteTx}
              disabled={isInvalid || isBeingVoted}
            >
              <S.VoteUpIcon />
              execute
            </S.VoteUpButton>
          </>
        ) : (
          <>
            {aboutToExecute ? (
              <>
                {(!exceedsIncomingLimit || canExceedIncomingLimit) &&
                  isSignFlowAvailable && (
                    <S.VoteUpButton
                      onClick={onVoteUpAndSign}
                      disabled={
                        !user?.proposal_vote_weight || isInvalid || isBeingVoted
                      }
                    >
                      <S.VoteUpIcon />
                      vote up {aboutToExecute && '& sign'}
                    </S.VoteUpButton>
                  )}
                {(!exceedsIncomingLimit || canExceedIncomingLimit) && (
                  <S.VoteUpButton
                    onClick={onVoteUpAndExecute}
                    disabled={
                      !user?.proposal_vote_weight || isInvalid || isBeingVoted
                    }
                  >
                    <S.VoteUpIcon />
                    vote up {aboutToExecute && '& execute'}
                  </S.VoteUpButton>
                )}
              </>
            ) : (
              <>
                {' '}
                {(!exceedsIncomingLimit || canExceedIncomingLimit) && (
                  <S.VoteUpButton
                    onClick={onVoteUpAndSign}
                    disabled={
                      !user?.proposal_vote_weight || isInvalid || isBeingVoted
                    }
                  >
                    <S.VoteUpIcon />
                    vote up
                  </S.VoteUpButton>
                )}
              </>
            )}
            <S.VoteDownButton
              onClick={onVoteDown}
              disabled={!user?.proposal_vote_weight || isBeingVoted}
            >
              <S.VoteDownIcon />
              vote down {aboutToReject && '& close'}
            </S.VoteDownButton>
          </>
        )}

        {exceedsIncomingLimit && (
          <p>
            Voting for this proposal will exceed the incoming limit of{' '}
            {toAccount?.name} account ({toAccount?.incoming_limit?.limit} USD)
            {canExceedIncomingLimit
              ? ', but you can still vote'
              : '. You can vote down'}
            .
          </p>
        )}
        {isInvalid && (
          <p>
            This transaction cannot be sent because {invalidAccount} been
            removed from the Enclave. You can vote down and close this
            transaction.
          </p>
        )}
        {voteError && (
          <S.ErrorText>
            {voteError}
            <br />
            An error occured. Please contact us at{' '}
            <a href="mailto:support@multik.io">support@multik.io</a>
          </S.ErrorText>
        )}
      </S.VoteWeightWrap>
    );
  },
);

interface VotesInfoProps {
  votes: TransactionVote[];
  resolved_by: string;
  resolved_at: string;
  exchange: string;
  status: TransactionStatusType;
}
const VotesInfo: FC<VotesInfoProps> = memo(
  ({ votes, resolved_by, resolved_at, status, exchange }) => {
    const { user: appUser } = useContext(AppContext);

    return (
      <S.StatusBarLineVotes>
        {exchange === 'DERIBIT' &&
        resolved_by &&
        ![
          TransactionStatus.CANCELED,
          TransactionStatus.CLOSED,
          TransactionStatus.FAILED,
        ].includes(status) ? (
          <S.StatusBarLineSubItem
            key={votes.length + 1}
            className="completed"
            dir={'none'}
          >
            <S.ConfirmationsVote>
              <S.CheckIcon />
              Executed by
              <S.ConfirmationsVoteEmail>
                {resolved_by} {resolved_by === appUser?.email ? '(you)' : ''}
              </S.ConfirmationsVoteEmail>
            </S.ConfirmationsVote>
            {resolved_at && <StatusLineDate date={resolved_at} />}
          </S.StatusBarLineSubItem>
        ) : null}
        {votes
          ?.sort(({ created_at: a }, { created_at: b }) =>
            moment(b).diff(moment(a)),
          )
          .map(({ vote, user, created_at }, index) => (
            <S.StatusBarLineSubItem
              key={index}
              className="completed"
              dir={vote > 0 ? 'up' : 'down'}
            >
              <S.ConfirmationsVote>
                <S.IconConfirmationsVote />
                {Math.abs(vote)}
                <S.ConfirmationsVoteEmail>
                  {user} {user === appUser?.email ? '(you)' : ''}
                </S.ConfirmationsVoteEmail>
              </S.ConfirmationsVote>
              <StatusLineDate date={created_at} />
            </S.StatusBarLineSubItem>
          ))}
      </S.StatusBarLineVotes>
    );
  },
);

interface StatusTitleProps {
  title: string;
  comment?: string;
  time?: string;
}
const StatusTitle: FC<StatusTitleProps> = memo(({ title, comment, time }) => {
  return (
    <S.StatusBarLineRow>
      <S.StatusBarLineTitle>
        <span>{title}</span>
        {comment ? comment : null}
      </S.StatusBarLineTitle>
      {time && <StatusLineDate date={time} />}
    </S.StatusBarLineRow>
  );
});

interface StatusLineDateProps {
  date: string | Date | number;
}
const StatusLineDate: FC<StatusLineDateProps> = memo(({ date }) => (
  <Tippy
    trigger="mouseenter"
    placement="top"
    theme="transparent"
    hideOnClick={false}
    content={
      <S.StatusBarLineDateTooltip>
        {formattedDate(new Date(date), 'YYYY-MM-DD HH:mm:ss')}
      </S.StatusBarLineDateTooltip>
    }
  >
    <S.StatusBarLineDate>{dateAgo(new Date(date))}</S.StatusBarLineDate>
  </Tippy>
));

interface StatusLineProps {
  tx: Transaction;
  line: StatusBar;
}
const StatusLine: FC<StatusLineProps> = memo(({ line, tx }) => {
  const { id, isVisible, getData, lines } = line;
  const { appSettings } = useContext(AppContext);
  const { title, status, comment, time } = getData(
    tx,
    appSettings.proposal_votes_value_to_approve,
  );
  const visible = isVisible(tx);

  if (!visible) return null;

  if (id === 'confirmations' && !tx?.votes?.length) return null;

  return (
    <S.StatusBarLineItem
      className={status}
      loading={
        status === 'active' && id !== 'confirmations' && id !== 'processing'
      }
    >
      <StatusTitle title={title} comment={comment} time={time} />
      {/* @ts-ignore */}
      {id === 'confirmations' && (
        <VotesInfo
          votes={tx.votes}
          exchange={String(tx.account_from?.exchange)}
          resolved_by={tx.resolved_by}
          resolved_at={tx.withdrawal_updated_at}
          status={tx.status}
        />
      )}
      {lines?.length && <StatusSubLines lines={lines} tx={tx} />}
    </S.StatusBarLineItem>
  );
});

interface StatusSubLinesProps {
  lines: StatusBar[];
  tx: Transaction;
}
export const StatusSubLines: FC<StatusSubLinesProps> = memo(({ lines, tx }) => {
  return (
    <S.StatusBarLineSubItems>
      {lines.map((line, index) => (
        <StatusSubLine key={index} line={line} tx={tx} />
      ))}
    </S.StatusBarLineSubItems>
  );
});

interface StatusSubLineProps {
  line: StatusBar;
  tx: Transaction;
}
export const StatusSubLine: FC<StatusSubLineProps> = memo(({ line, tx }) => {
  const { isVisible, getData } = line;
  const {
    title,
    status,
    comment,
    time,
    commentColor = 'var(--warning)',
  } = getData(tx);
  const visible = isVisible(tx);

  if (!visible) return null;

  return (
    <S.StatusBarLineSubItem className={status}>
      <div>
        {title}
        {comment && (
          <S.StatusBarLineSubItemError commentColor={commentColor}>
            {comment}
          </S.StatusBarLineSubItemError>
        )}
      </div>
      {time && <StatusLineDate date={time} />}
    </S.StatusBarLineSubItem>
  );
});

interface StatusBarProps {
  tx: Transaction;
  voteUp?: any;
  voteDown?: any;
  voteError?: any;
  voteUpAndSign?: any;
  executeTx?: any;
}
export const StatusBar: FC<StatusBarProps> = memo(
  ({ tx, voteUp, voteDown, voteUpAndSign, executeTx, voteError }) => {
    const { openModal, user } = useContext(AppContext);
    const isLongProcessing =
      [StatusType.PENDING, StatusType.SENT, StatusType.RECEIVED].includes(
        tx.status,
      ) && moment(tx.updated_at).diff(moment().utc(), 'hours') <= -24;
    return (
      <S.StatusBar>
        <CreatedLine
          creator={tx.created_by as string}
          createdAt={tx.created_at}
        />
        <StatusLines tx={tx} />
        {(tx.status === 'QUEUED' || tx.status === 'AWAITING_EXECUTE') && (
          // @ts-ignore
          <VoteButtons
            voteError={voteError}
            tx={tx}
            voteUp={voteUp}
            voteDown={voteDown}
            executeTx={executeTx}
            voteUpAndSign={voteUpAndSign}
          />
        )}
        {isLongProcessing && (
          <S.VoteWeightWrap>
            <S.ManualResolveText>
              This transaction has been processing on the exchange for more than
              24 hours.
              {user?.proposal_vote_weight && (
                <>
                  <br />
                  <br /> Please check the transaction details on the exchange
                  and manually resolve it.
                </>
              )}
            </S.ManualResolveText>
            {user?.proposal_vote_weight && (
              <S.ManualResolveButton
                onClick={() =>
                  openModal({
                    title: 'Select transaction status',
                    width: '480px',
                    outsideClickClose: false,
                    component: () => <ManualResolve id={tx.id} />,
                  })
                }
              >
                <S.ManualCheckIcon />
                Manually resolve
              </S.ManualResolveButton>
            )}
          </S.VoteWeightWrap>
        )}
      </S.StatusBar>
    );
  },
);
